import clients from "../video/clients.mp4"
import start from "../video/start.mp4"
import contact from "../video/contact.mp4"
import prices from "../video/prices.mp4"
import about from "../video/about.mp4"
import plok from "../video/plok.mp4"
import polsat from "../video/polsat.mp4"
import tvp from "../video/tvp.mp4"
import portfolio from "../video/portfolio.mp4"
import zdrowie from "../video/zdrowie.mp4"

export const pages = [
  {
    name: "START",
    slug: "/",
    video: start,
  },
  {
    name: "O NAS",
    slug: "about",
    video: about,
    desc: [
      {
        title: "JESTEŚMY",
        text: `agencją full-serwis z zakresu BTL i ATL. Zajmujemy się wszelkimi aspektami działalności filmowej i reklamowej od pomysłu do zakończenia realizacji i wybiórczo na każdym poziomie, od kreacji po wytworzenie produktu finalnego.`,
      },
      {
        title: "TWORZYMY",
        text: `filmy i projekty na potrzeby: TV, filmu, prasy, wizualizacji i animacji architektonicznych, reklamy wizualnej, reklamy zewnętrznej, corportate identity, internetu, multimediów, gadżetów reklamowych.`,
      },
      {
        title: "WYKONUJEMY",
        text: `zdjęcia filmowe najnowszymi technikami cyfrowymi oraz pełną postprodukcję wraz z tworzeniem komputerowych efektów specjalnych.`,
      },
      {
        title: "ROBIMY",
        text: `zdjęcia reklamowe (katalogowe, packshot, żywności, makrofotografia, architektury, wnętrza, outdoor, dekoracyjne, fotomodeling ) oraz reportażowe.`,
      },
      {
        title: "OPRACOWUJEMY",
        text: `kompleksową strategię działań i dobieramy najlepsze narzędzia w komunikacji marketingowej klienta.`,
      },
      {
        title: "DOSTOSOWUJEMY",
        text: `oferty do potrzeb i możliwości klienta (indywidualne wyceny).`,
      },
    ],
  },
  {
    name: "3majZDROWIE",
    slug: "3maj-zdrowie",
    video: zdrowie,
    desc: [
      {
        title: "3maj ZDROWIE",
        text: `To kanał dla tych, którzy już wiedzą, że w życiu nie ma drogi na skróty. W zachowaniu zdrowia sama tabletka nie pomoże. Dobre zdrowie to nie tylko brak choroby, to świetne samopoczucie, opanowanie stresu i wiedza jak działać, kiedy chorobowy "dziad" nadchodzi. Oby nie!!! Pokażę możliwości pracy ze sobą ponieważ interesuje mnie dobrostan człowieka, jego emocje i zmiany. Mam nadzieję, że pojawi się tu wiele inspiracji , które pozwolą przestać walczyć ze sobą i z życiem. Czekam na Państwa z radością i otwartym serduchem. Czekam na cud przemiany.`,
      },
      {
        text: `Grażyna Dobroń - autorka Trójkowych audycji "Przychodzi Dobroń do lekarza", "Dobronocka", "Instrukcja obsługi człowieka", książek "Zdrowym być" i "Instrukcja (samo)obsługi człowieka". W 2001 roku  za reportaż "Fire walk" - boso po rozżarzonych węglach" otrzymała Grand Prix URTI.

        Zapraszam też do współpracy psychologów, terapeutów, a także wydawnictwa książkowe.`,
      },
      {
        title: "WSPARCIE",
        text: `Będzie nam bardzo miło, jeżeli zechcesz nas wesprzeć finansowo i pomóc w rozwoju naszego kanału. Jeśli bliskie są Tobie nasze cele - poniżej podajemy konto, na które możesz przekazać nam darowiznę.`,
      },
    ],
  },
  {
    name: "PLjestOK",
    slug: "pl-ok",
    video: plok,
    desc: [
      {
        title: `TWORZYMY`,
        text: `„Niezbędnik Kultury” - projekt dla miłośników krajoznawczych wędrówek po Polsce. To będzie nieoczywiste zwiedzanie miast i miasteczek, poznawanie zabytków ojczystej kultury, przywołanie historycznych postaci i wydarzeń przyprawione szczyptą humoru. Chcemy promować Polskę wystawiając przedstawienia historyczno-krajoznawcze oraz stworzyć portal promujący rękodzieło, agroturystykę i kulturę regionalną.`,
      },
      {
        title: `WSPÓŁPRACA`,
        text: `Jeżeli jesteś zainteresowany naszym projektem skontaktuj się z nami. Przygotujemy ofertę  sponsoringu.`,
      },
      {
        title: "WSPARCIE",
        text: `Będzie nam bardzo miło, jeżeli zechcesz nas wesprzeć finansowo i pomóc w rozwoju naszego kanału. Jeśli bliskie są Tobie nasze cele - poniżej podajemy konto, na które możesz przekazać nam darowiznę.`,
      },
    ],
  },
  {
    name: "TVP",
    slug: "tvp",
    video: tvp,
    desc: [
      {
        title: "WSPÓŁPRACUJEMY",
        text: `Z Panem Wojciechem Nowakowskim, który był podwykonawcą Telewizji Polskiej. Dla TVP wyprodukowaliśmy około 120 odcinków cyklu filmów "Zaproszenie", przedstawiających  turystyczno-krajoznawczo-historyczną gawędę o Polsce i jej ciekawych, mało znanych  zakątkach. Na przestrzeni lat filmy były emitowane na antenach TVP1, TVP3, TVP KULTURA, TVP HISTORIA oraz TVP POLONIA, na której ukazują się stale i są oglądane na całym świecie.\n\n"Zaproszenie" zdobyło wiele prestiżowych nagród:`,
      },
      {
        title: "1995/96/97",
        text: `Nagroda "KOSA" dla autora najlepszej publikacji telewizyjnej poświęconej turystyce od Urzędu Kultury Fizycznej i Turystyki 1997 ‐ I miejsce na przeglądzie filmów turystycznych podczas targów.`,
      },
      {
        title: "1997",
        text: `Nagroda "KOSA" dla autora najlepszej publikacji telewizyjnej poświęconej turystyce od Urzędu Kultury Fizycznej i Turystyki.`,
      },
      {
        title: "1998",
        text: `I miejsce oraz wyróżnienie na Międzynarodowym Przeglądzie Filmów Turystycznych "Tour-Film" w Poznaniu.`,
      },
      {
        title: "1999",
        text: `Nagroda za najlepszy film promujący walory turystyczne regionu na Międzynarodowym Przeglądzie Filmów Turystycznych "Tour-Film".`,
      },
      {
        title: "2000",
        text: `II miejsce w kategorii filmy turystyczne na Międzynarodowym Przeglądzie Filmów Turystycznych "Tour-Film" w Poznaniu. Nagroda Uniwersytetu Jagiellońskiego za niekonwencjonalną popularyzację wiedzy o uniwersytecie w konkursie na publikacje telewizyjne z okazji 600-lecia Akademii Krakowskiej. I nagroda w konkursie na najlepsze publikacje telewizyjne w konkursie im. M.Orłowicza Polskiej Organizacji Turystycznej.`,
      },
      {
        title: "2003",
        text: `I nagroda w kategorii: film turystyczny zrealizowany przez telewizję na Międzynarodowym Festiwalu Filmów Turystycznych "Tour-Film".`,
      },
      {
        title: "2004",
        text: `Wyróżnienie Specjalne za profesjonalne promowanie walorów turystycznych Polski oraz za wybitne walory edukacyjne prezentowanych filmów na Międzynarodowym Festiwalu Filmów Turystycznych "Tour-film".`,
      },
    ],
  },
  {
    name: "POLSAT",
    slug: "polsat",
    video: polsat,
    desc: [
      {
        title: "IMPERIUM SŁOŃCA",
        text: `Od 2011 roku współpracujemy z Telewizją Polsat uczestnicząc w najważniejszych wydarzeniach sportowych i społecznych. 

        Dla Polsatu Sport projektujemy tła studyjne, oprawę graficzną oraz obsługujemy stanowiska grafiki online (ORAD 3Dplay, Viz Trio, Viz Multiplay). 
        
        Wykonywaliśmy też infografikę oraz tła studyjne na potrzeby Polsatu News. 
        
        Obsługiwaliśmy graficznie programy takie jak: "Państwo w Państwie", "Skandaliści", "Interwencja", "Debata Tygodnia", "Prezydenci i Premierzy", "Cafe Futbol", "Puncher", "UFC", "Gale Boksu", "#7Strefa", "Magazyn żużlowy", "Magazyn Fortuna 1 ligi", "Magazyn Polska 2017", "PlusLiga" i wiele innych. 
        
        Kompleksowo projektujemy wygląd studia (kolorystyka, animacje, infografiki, wizytówki, jingle, opracowanie muzyczne) wraz z jego wstępną wizualizacją.
        `,
      },
    ],
  },
  {
    name: "PORTFOLIO",
    slug: "portfolio",
    video: portfolio,
  },
  {
    name: "KLIENCI",
    slug: "clients",
    video: clients,
  },
  {
    name: "OFERTA",
    slug: "prices",
    video: prices,
    desc: [
      {
        title: "MISJA NA MARSA",
        text: `Doświadczenie, ciekawość i pasja to cechy, które na co dzień oferujemy Klientom. Każde zlecenie jest dla nas misją na Marsa a czasami nawet i dalej. Słuchamy i staramy się zrozumieć szczególnie to, co zostało ukryte między słowami. Płynnie przechodzimy od koncepcji do wykonania wybierając najlepszy stosunek jakości do ceny. 

        Jeżeli potrzebujesz do swojej firmy logo, wizytówki, teczki, papier firmowy i wszystkie inne rzeczy związane z identyfikacją firmy zapraszamy. Zadbamy też o Twoją stronę internetową i social media. Stworzymy dla Ciebie wizualizacje, filmy reklamowe oraz zaplanujemy kampanię. 
        
        Po naszej stronie stoi ekipa ludzi mających wieloletnie doświadczenie telewizyjne i filmowe.
        
        Dysponujemy pełnym wyposażeniem studia telewizyjnego i możemy prowadzić transmisję na żywo z każdego wydarzenia publicznego, spotkania, koncertu i meczu - które posiada zasięg telefonii komórkowej.
         
        Oferujemy Państwu też możliwość udziału w naszym projekcie PLjestOK i 3majZDROWIE. Zapraszamy do współpracy.`,
      },
    ],
  },
  {
    name: "KONTAKT",
    slug: "contact",
    video: contact,
  },
]
