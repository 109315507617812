import React from "react"
import { useWindowSize } from "react-use"

export const WindowSize = React.createContext({
  width: "",
  height: "",
})

export const WindowSizeProvider = ({ children }) => {
  const { width, height } = useWindowSize()

  return (
    <WindowSize.Provider
      value={{
        width,
        height,
      }}
    >
      {children}
    </WindowSize.Provider>
  )
}
