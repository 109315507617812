const location = "/image/logos"

const logos = [
  {
    id: 1,
    alt: "",
    src: `${location}/AGRAART.png`,
    href: "https://www.agraart.pl/",
  },
  {
    id: 21,
    alt: "",
    src: `${location}/ZEISS.png`,
    href: "https://www.zeiss.pl/",
  },
  {
    id: 18,
    alt: "",
    src: `${location}/POLSAT.png`,
    href: "https://www.polsat.pl/",
  },
  {
    id: 19,
    alt: "",
    src: `${location}/PUBLICIS.png`,
    href: "http://publicis.pl/",
  },
  {
    id: 10,
    alt: "",
    src: `${location}/JANSPORT.png`,
    href: "https://www.jansport.com/",
  },
  {
    id: 6,
    alt: "",
    src: `${location}/FULLSCREEN.png`,
    href: "https://fullscreen.com.pl/",
  },
  {
    id: 7,
    alt: "",
    src: `${location}/HILTANDCO.png`,
    href: "https://www.hiltco.online/",
  },
  {
    id: 14,
    alt: "",
    src: `${location}/MILEK.png`,
    href: "http://www.milekdesign.pl/",
  },
  {
    id: 15,
    alt: "",
    src: `${location}/MOC.png`,
    href: "https://ministryofcreativity.pl/",
  },
  { id: 2, alt: "", src: `${location}/APACAD.png`, href: "http://apacad.pl/" },
  {
    id: 20,
    alt: "",
    src: `${location}/SEENTECHNOLOGIE.png`,
    href: "http://www.seentechnologie.pl/",
  },
  {
    id: 17,
    alt: "",
    src: `${location}/MPWIK.png`,
    href: "https://www.mpwik.com.pl/",
  },
  {
    id: 3,
    alt: "",
    src: `${location}/BRODZINSKA.png`,
    href: "https://www.facebook.com/BrodzinskaAtelier/",
  },
  { id: 8, alt: "", src: `${location}/IMAGIS.png`, href: "http://imagis.pl/" },
  {
    id: 13,
    alt: "",
    src: `${location}/MEA_GROUP.png`,
    href: "http://www.meagroup.pl/",
  },
  {
    id: 16,
    alt: "",
    src: `${location}/MPLUSM.png`,
    href: "https://www.mplusm.pl/",
  },
  {
    id: 4,
    alt: "",
    src: `${location}/DROGOMEX.png`,
    href: "http://www.drogomex.pl/",
  },
  {
    id: 9,
    alt: "",
    src: `${location}/IMPER.png`,
    href: "http://imper.com.pl/",
  },
  {
    id: 11,
    alt: "",
    src: `${location}/MAPAMAP.png`,
    href: "http://www.mapamap.pl/",
  },
  {
    id: 5,
    alt: "",
    src: `${location}/FOODPICTURES.png`,
    href: "http://www.food-pictures.com/",
  },
  {
    id: 12,
    alt: "",
    src: `${location}/MASFALT.png`,
    href: "http://www.masfalt.pl/",
  },
]

export default logos
