import React, { useState, useEffect } from "react"
import { NavLink, Link } from "react-router-dom"
import { pages } from "../components/MenuElements"
import logo from "../images/maxlook_logo.svg"
import close from "../images/close.svg"
import menu from "../images/menu.svg"
import gsap from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

const Nav = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  })

  const menuItems = pages.slice(1).map(page => (
    <li key={page.slug}>
      <NavLink
        to={page.slug}
        onClick={() => {
          setOpen(false)
          gsap.to(window, { duration: 0, scrollTo: 0 })
        }}
        activeClassName="active"
      >
        {page.name}
      </NavLink>
    </li>
  ))

  const Toggle = () => {
    setOpen(!open)
  }

  return (
    <nav>
      <div className="navContainer">
        <Link to="/" className="logo">
          <img src={logo} alt="maxlook inc." />
        </Link>
        <img
          alt=""
          src={open ? close : menu}
          className="toggle"
          onClick={Toggle}
        />
        <div className={open ? "menu opened" : "menu"}>
          <ul>{menuItems}</ul>
          <div className="center social">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/MAX-LOOK-102758661498789"
            >
              <svg
                fill="#fff"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m75 512h167v-182h-60v-60h60v-75c0-41.355469 33.644531-75 75-75h75v60h-60c-16.542969 0-30 13.457031-30 30v60h87.292969l-10 60h-77.292969v182h135c41.355469 0 75-33.644531 75-75v-362c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75zm-45-437c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v362c0 24.8125-20.1875 45-45 45h-105v-122h72.707031l20-120h-92.707031v-30h90v-120h-105c-57.898438 0-105 47.101562-105 105v45h-60v120h60v122h-137c-24.8125 0-45-20.1875-45-45zm0 0" />
              </svg>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/maxlook_ml/"
            >
              <svg
                fill="#fff"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m75 512h362c41.355469 0 75-33.644531 75-75v-362c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75zm-45-437c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v362c0 24.8125-20.1875 45-45 45h-362c-24.8125 0-45-20.1875-45-45zm0 0" />
                <path d="m256 391c74.4375 0 135-60.5625 135-135s-60.5625-135-135-135-135 60.5625-135 135 60.5625 135 135 135zm0-240c57.898438 0 105 47.101562 105 105s-47.101562 105-105 105-105-47.101562-105-105 47.101562-105 105-105zm0 0" />
                <path d="m406 151c24.8125 0 45-20.1875 45-45s-20.1875-45-45-45-45 20.1875-45 45 20.1875 45 45 45zm0-60c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15-15-6.730469-15-15 6.730469-15 15-15zm0 0" />
              </svg>
            </a>

            {/* <p className='languages'>EN</p> */}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
