import React from "react"
import logos from "../components/logos"
import Grow from "@material-ui/core/Grow"

const Clients = () => {
  const images = logos.map((img, index) => (
    <div key={index}>
      <Grow
        in
        style={{ transformOrigin: "0 0 0" }}
        {...{ timeout: 150 * index }}
      >
        <a href={img.href} rel="noopener noreferrer" target="_blank">
          <div className="logos" key={img.id}>
            <img height="100" width="100" src={img.src} alt={img.alt} />
          </div>
        </a>
      </Grow>
    </div>
  ))

  return (
    <div className="description center clients row my-5 m-0 justify-content-center">
      {images}
    </div>
  )
}

export default Clients
