import React, { useState, useContext, useRef } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { pages } from "../components/MenuElements"
import Payments from "./Payments"
import Clients from "../components/Clients"
import speaker from "../images/speaker.svg"
import speakerMute from "../images/speaker-mute.svg"
import Contact from "../components/Contact"
import { WindowSize } from "../context/WindowSize"
import CustomTypeWriter from "./CustomTypeWriter"
import logo from "../images/maxlook_logo.svg"

const Content = () => {
  const ref = useRef()
  const [ready, setReady] = useState(false)
  const [mute, setMute] = useState(true)
  const { width, height } = useContext(WindowSize)
  const aspect = (height * 16) / 9

  const routePages = pages.map(({ name, slug, desc, video }) => (
    <Route exact={slug === "/"} key={slug} path={`/${slug}`}>
      {slug === "/" && ready && <CustomTypeWriter ready={ready} />}
      <div style={{ height }} className="video-container">
        <div className={`video-sheet center ${ready && "slide"}`}>
          <img src={logo} alt="maxlook inc." />
        </div>
        <video
          onLoadStart={() => setReady(false)}
          onCanPlay={() => setReady(true)}
          ref={ref}
          style={{
            width: width > aspect ? "100%" : aspect,
          }}
          poster={`/image/poster/${slug !== "/" ? slug : "start"}.jpg`}
          muted={mute}
          autoPlay
          loop
        >
          <source src={video} />
        </video>
      </div>
      {desc && (
        <div className="description row my-5 justify-content-center">
          {(slug === "3maj-zdrowie" || slug === "pl-ok") && (
            <a
              key={name}
              className="text-center col-12"
              href={`https://www.${name}.pl`.toLowerCase()}
            >
              <h3 className="font-weight-bolder">
                {`www.${name}.pl`.toLowerCase()}
              </h3>
            </a>
          )}
          {desc.map(({ title, text }, index) => (
            <div className="col-sm-8 m-2" key={index}>
              {title && <h3>{title}</h3>}
              <p className="m-0">{text}</p>
            </div>
          ))}
          {(slug === "3maj-zdrowie" || slug === "pl-ok") && <Payments />}
        </div>
      )}
      {slug === "portfolio" && (
        <button className="muteButton" onClick={() => setMute(!mute)}>
          <img src={mute ? speakerMute : speaker} alt="mute" />
        </button>
      )}
      {slug === "clients" && <Clients />}
      {slug === "contact" && <Contact />}
    </Route>
  ))
  return (
    <Switch className="switch-wrapper">
      {routePages}
      <Redirect to="/" />
    </Switch>
  )
}

export default Content
