import React, { useEffect } from "react"
import "./style/style.scss"
import Nav from "./components/Nav"
import Content from "./components/Content"
import Footer from "./components/Footer"
import { BrowserRouter as Router } from "react-router-dom"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import { WindowSizeProvider } from "./context/WindowSize"
import ReactGA from "react-ga"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#05c400",
    },
  },
})

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_CLIENT)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <Router>
      <WindowSizeProvider>
        <div className="app-container">
          <ThemeProvider theme={theme}>
            <Nav />
            <Content />
            <Footer />
          </ThemeProvider>
        </div>
      </WindowSizeProvider>
    </Router>
  )
}

export default App
