import React, { useState } from "react"
import { TextField, Button } from "@material-ui/core"
import emailjs from "emailjs-com"

const Contact = () => {
  const [open, setOpen] = useState(false)

  const onSubmit = e => {
    e.preventDefault()
    setOpen(true)
    emailjs
      .sendForm(
        "service_wx6xw1h",
        "template_yumlz1h",
        e.target,
        "user_hat9iHHCZs0oCU37220tL"
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
  }

  const handleReset = () => {
    document.getElementById("myForm").reset()
  }

  return (
    <div className="row contact">
      <div className="col-12 col-sm-6 mt-5 mt-sm-0 center">
        <div className="">
          <h1>MAX LOOK</h1>
          <h2 className="mb-2">Michał Łukaszyk</h2>
          <p>ul. Rajskich Ptaków 9/2</p>
          <p>02-816 Warszawa</p>
          <p>NIP 951-184-28-22</p>
          <p className="mail">
            <a href="mailto:biuro@maxlook.pl">biuro@maxlook.pl</a>
          </p>
        </div>
      </div>
      <div className="fields col-12 col-sm-6 border-left">
        {!open ? (
          <h2>Formularz kontaktowy</h2>
        ) : (
          <h2 style={{ color: "#05c400" }}>Formularz wysłany</h2>
        )}

        <form id="myForm" className="fields" onSubmit={onSubmit}>
          <TextField
            name="fullName"
            fullWidth
            label="Imię i nazwisko"
            variant="filled"
            required
          />
          <TextField
            name="email"
            fullWidth
            label="Adres email"
            variant="filled"
            required
          />
          <TextField
            name="title"
            fullWidth
            label="Tytuł widomości"
            variant="filled"
            required
          />
          <TextField
            name="message"
            rows={4}
            rowsMax={14}
            multiline
            fullWidth
            label="Treść widomości"
            variant="filled"
            required
          />
          <Button
            disabled={open}
            type="submit"
            fullWidth
            onClick={handleReset}
            style={{ marginTop: "1rem", textAlign: "center" }}
            variant="outlined"
            color="primary"
          >
            Wyślij
          </Button>
        </form>
      </div>
    </div>
  )
}

export default Contact
