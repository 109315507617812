import { TextField } from "@material-ui/core"
import React, { useState } from "react"
import darowizna from "../images/payment/darowizna.png"
import paypal from "../images/payment/paypal.png"
import { useLocation } from "react-router-dom"

const Zdrowie = () => {
  let { pathname } = useLocation()
  const [data, setData] = useState(null)
  const bankNumber = process.env.REACT_APP_BANK_NUMBER
  const currentSlug = pathname === "/pl-ok" ? "PLjestOK" : "3majZDROWIE"

  const handleFocus = e => {
    e.target.select()
  }

  const toClipboard = () => {
    setData(bankNumber)
  }

  return (
    <div className="col-12 col-sm-8 m-2">
      <div className="payment-images row">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.paypal.com/paypalme/PLjestOK"
        >
          <img src={paypal} alt="paypal" />
        </a>
        <div onClick={toClipboard} disabled={data} className="cursor">
          <img src={darowizna} alt="darowizna" />
        </div>
        {data && (
          <div className="col-12 align-center mt-4 inputBank">
            <TextField
              name="numer konta"
              label="Numer konta:"
              className="inputBank"
              fullWidth
              onFocus={handleFocus}
              variant="outlined"
              readOnly
              value={bankNumber}
            />
            <TextField
              name="tytuł"
              label="Tytułem:"
              className="inputBank"
              fullWidth
              onFocus={handleFocus}
              variant="outlined"
              readOnly
              value={`Darowizna na projekt ${currentSlug}`}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Zdrowie
