import Typewriter from "typewriter-effect"
import React from "react"

const text = `„Nasze pomysły,\nprzebijają się przez\nnajgrubszy beton\nludzkiego umysłu”.`

const CustomTypeWriter = ({ ready }) => {
  return (
    <Typewriter
      options={{
        cursor: "",
        loop: false,
        delay: 30,
      }}
      onInit={typewriter => {
        ready && typewriter.typeString(text).start()
      }}
    />
  )
}

export default CustomTypeWriter
