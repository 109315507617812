import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
// ;(function (d, s, id) {
//   var js,
//     fjs = d.getElementsByTagName(s)[0]
//   if (d.getElementById(id)) return
//   js = d.createElement(s)
//   js.id = id
//   js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"
//   fjs.parentNode.insertBefore(js, fjs)
// })(document, "script", "facebook-jssdk")

ReactDOM.render(<App />, document.getElementById("root"))
